import React, { useRef, useState } from 'react'
import { UlupinarGalleryProps } from './type'
import { useWidth } from '@/core/hooks/useWidthResize'
import { useApp } from '@/core/contexts/app'
import Slider from 'react-slick'
import Image from '@/components/base/image/Image'
import Icon from '@/components/base/icon/Icon'
import Button from '@/components/base/button/Button'
import { classnames } from 'begonya/functions'
import Anchor from '@/components/base/anchor'
import { path } from '@/core/utils'
import Fancybox from '@/components/base/fancybox/Fancybox'
import { Container } from '@/components/base/gridview'

const UlupinarGallery: React.FC<UlupinarGalleryProps> = ({ data }) => {
  const width = useWidth()
  const app = useApp()
  const slick = useRef<Slider>(null)
  const settings = {
    dots: false,
    slidesToShow: width < 700 ? 1 : width < 1000 ? 2 : width < 1500 ? 3 : 4,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
  }

  return (
    <div className="UlupinarGallery">
      <Container className="UlupinarGallery-content" size="medium">
        <div
          className="UlupinarGallery-content-title"
          dangerouslySetInnerHTML={{
            __html: app.settings.translations['gallery-title'],
          }}
        />
        <button
          className="UlupinarGallery-content-arrow-prev"
          onClick={() => slick.current?.slickPrev()}
        >
          <Icon name="arrow-back" />
        </button>
        <Fancybox>
          <Slider {...settings} ref={slick}>
            {data?.map((item, index) => (
              <Anchor
                data-fancybox="gallery"
                href={path.asset(app.environment, item.image.src)}
                key={index}
                className="ggm-workshop-gallery-item"
              >
                <div
                  style={{
                    padding: width < 800 ? '10px' : '',
                  }}
                  className="UlupinarGallery-content-item"
                >
                  <figure>
                    <Image {...item.image} alt="" />
                  </figure>
                </div>
              </Anchor>
            ))}
          </Slider>
        </Fancybox>
        <button
          className="UlupinarGallery-content-arrow-next"
          onClick={() => slick.current?.slickNext()}
        >
          <Icon name="arrow-forward" />
        </button>
      </Container>
    </div>
  )
}
export default UlupinarGallery
